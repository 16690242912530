import Layout from "../components/layout";
import Helmet from "react-helmet";
import Header from "../components/header";
import ReactMarkdown from "react-markdown";
import React from "react";
import {graphql} from "gatsby";

const MediaPage = ({data}) => {
    let siteType = process.env.GATSBY_API_URL;
    let mediaPageData = data.allStrapiPageMedias.edges[0].node;
    let mediaData;

    if (siteType === "prod") {
        mediaData = data.allStrapiMedias.edges.filter(edges => edges.node.status === "published");
    } else if (siteType === "dev") {
        mediaData = data.allStrapiMedias.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
    }
    mediaData.sort((a, b) => new Date(b.node.date) - new Date(a.node.date));


    function formatDate(date) {
        var monthNames = [
            "Januar", "Februar", "März",
            "April", "Mai", "Juni", "Juli",
            "August", "September", "Oktober",
            "November", "Dezember"
        ];

        let updateDate = date.split("-");
        let day = updateDate[2];
        let monthIndex = updateDate[1];
        let year = updateDate[0];

        return day + ". " + monthNames[monthIndex - 1] + " " + year;
    }

    function getMediaCategory(category) {
        switch (category) {
            case "In_den_Medien":
                return "In den Medien";
            case "Konferenzen_Auftritte":
                return "Konferenzen / Auftritte";
            case "Studien_Berichte":
                return "Studien / Berichte";
            default:
                return category;
        }
    }

    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="media">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Paixon</title>
                <meta name="description"
                      content="Erfahren Sie mehr zu den Aktivitäten von Paixon. Hier finden Sie Medienbeiträge, Zusammenarbeiten mit Bildungsinstituten und Auftritte an Veranstaltungen."/>
            </Helmet>
            <Header className="media" slogan={mediaPageData.slogan}
                    blogphoto={{backgroundImage: `url(${mediaPageData.Photo.publicURL})`}}
                    offersData={offersData}/>
            <main className="container media pt">
                <h1>Media</h1>
                <div className="mediaMarkdown">
                    <ReactMarkdown children={mediaPageData.content} className="mediaElementText"/>
                </div>

                <div className="mediaList">
                    {mediaData.map(media => {
                            let updateDate = formatDate(media.node.date);
                            let mediaCategory = getMediaCategory(media.node.kategorie);
                            return (
                                <div className="mediaElement">
                                    <div className="mediaPhoto"
                                         style={{backgroundImage: `url(${media.node.media_photo.publicURL})`}}></div>

                                    <div className="mediaInfo">
                                        <div className="mediaElementContext">
                                            <h3>{media.node.title}</h3>
                                            <p className="publishInfos">{updateDate + ' - ' + mediaCategory}</p>
                                            <ReactMarkdown children={media.node.content}  className="mediaElementText"/>
                                        </div>
                                        <a className="mediaLink" href={media.node.link} target="_blank">
                                            <div className='mediaLink-text'>Beitrag anzeigen</div></a>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </main>
        </Layout>
    )
}

export default MediaPage


export const pageQuery = graphql`  
  query MediaPageQuery {
  allStrapiPageMedias {
    edges {
      node {
        slogan
        content
        Photo {
          publicURL
        }
      }
    }
  }
  allStrapiMedias {
    edges {
      node {
        content
        date
        status
        title
        kategorie
        link
        media_photo {
          publicURL
        }
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
 }
`
